import { useState, useEffect } from '@/common'
import { Space, Switch, Input, Select, TreeSelect } from 'antd';
import './SwitchInput.scss';

function Main(props: any) {
    const [datas, setDatas] = useState<any>({
        valid: false
    });
    const [default_status, setDefaultStatus] = useState(true)

    const onChange = (name: string, data: any) => {
        let one = { ...datas }
        one[name] = data
        setDatas(one)
        props.onChange(one)
        if (props.onChanged && typeof (props.onChanged) == "function") props.onChanged(one)
    }

    useEffect(() => {
        if (default_status) {
            if (props.value) {
                setDatas(props.value)
            } else if (props.defaultValue) {
                setDatas(props.defaultValue)
                props.onChange(props.defaultValue)
            }
            setDefaultStatus(false)
        }
    }, [props])

    return (
        <Space className='SwitchInput'>
            <Switch checked={datas.valid} onChange={(e) => {
                onChange('valid', e)
            }} />
            {
                datas.valid && props.inputs.map((e: any, k: number) => {
                    if (e.input === 'Input') {
                        return <div className='item' key={k}>
                            <div>{e.label}：</div>
                            <Input value={datas[e.name]} placeholder={`请输入${e.label}`} {...e.property} onChange={(ele: any) => {
                                onChange(e.name, ele.target.value)
                            }} />
                        </div>
                    }
                    if (e.input === 'Select') {
                        return <div className='item' key={k}>
                            <div>{e.label}：</div>
                            <Select
                                value={datas[e.name]}
                                placeholder={`请选择${e.label}`}
                                onChange={(ele: any) => {
                                    onChange(e.name, ele)
                                }} {...e.property}>
                            </Select>
                        </div>
                    }
                    if (e.input === 'Linked') {
                        let rt = <span className='gray'>空</span>
                        let linked = e.linked.options[datas[e.linked.name]]
                        if (!linked) return rt
                        if (linked.input === 'Select') {
                            rt = <Select
                                value={datas[e.name]}
                                placeholder={`请选择${linked.label}`}
                                onChange={(ele: any) => {
                                    onChange(e.name, ele)
                                }} {...linked.property}>
                            </Select>
                        }
                        if (linked.input === 'TreeSelect') {
                            rt = <TreeSelect
                                value={datas[e.name]}
                                placeholder={`请选择${linked.label}`}
                                onChange={(ele: any) => {
                                    onChange(e.name, ele)
                                }} {...linked.property}>
                            </TreeSelect>
                        }
                        if (linked.input === 'Input') {
                            <Input value={datas[e.name]} placeholder={`请输入${linked.label}`} onChange={(ele: any) => {
                                onChange(e.name, ele.target.value)
                            }}  {...linked.property} />
                        }
                        return rt
                    }
                })
            }
            {/* <Form
                form={form}
                layout='inline'
                onValuesChange={onValuesChange}
            >
                <Form.Item
                    name="valid"
                >
                    <Switch onChange={(e) => {
                        setValid(e)
                    }} />
                </Form.Item>
                {valid && props.children}
            </Form> */}
        </Space>
    )
}

export default Main