import { useEffect, post, useRef, useState, Func } from '@/common'
import { Select, Button, Divider, Space, Input, Popconfirm, message } from 'antd';
import {
  PlusOutlined
} from '@ant-design/icons';
import './ClassifySelect.scss';

function Main(props: any) {
  // const location = useLocation()
  // const pathname = props.apiUrl || location.pathname
  const ref = useRef<any>()
  const [inputValue, setInputValue] = useState();
  const [inputStatus, setInputStatus] = useState<any>();
  const [value, setValue] = useState<any>();

  const add = () => {
    if (Func.isEmpty(inputValue)) {
      message.error(`${props.name}分类名称必填`);
      setInputStatus('error')
      return
    }
    setValue(undefined)
    post('/classify/add', {
      type: props.type,
      name: inputValue
    }).then((data) => {
      if (data.code) return
      setInputValue(undefined)
      ref.current.focus()
      if (props.addSuccess) {
        props.addSuccess()
      }
    })
  }
  const del = () => {
    if (value === undefined || value.length === 0) {
      message.error(`请先选择需要删除的${props.name}分类`);
      return
    }
    post('/classify/del', value).then((data) => {
      if (data.code) return
      onChange(undefined)
      if (props.delSuccess) {
        props.delSuccess()
      }
    })
  }

  const onChange = (e: any) => {
    setValue(e)
    props.onChange(e)
  }

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  return (
    <Select
      className='classify_select'
      mode="multiple"
      placeholder={`请选择${props.name}分类`}
      optionLabelProp="label"
      allowClear
      value={value}
      dropdownRender={menu => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Input
              placeholder={`请输入${props.name}分类名称`}
              ref={ref}
              value={inputValue}
              onChange={(e: any) => {
                if (Func.isEmpty(e.target.value)) {
                  setInputStatus('error')
                } else {
                  setInputStatus(undefined)
                }
                setInputValue(e.target.value)
              }}
              status={inputStatus}
            />
            <Button type="text" icon={<PlusOutlined />} onClick={add}>
              添加分类
            </Button>
            <Popconfirm
              title={`确认删除该${props.name}？`}
              onConfirm={del}

            >
              <Button type="text" danger >
                删除选中
              </Button>
            </Popconfirm>
          </Space>
        </>
      )}
      options={props.classifys.map((e: any) => {
        return {
          label: e.name,
          value: e.id,
        }
      })}
      onChange={onChange}
    />
  )
}
export default Main