import { useState, get, useEffect, forwardRef, useImperativeHandle, useLocation, Func, useRef, MyIcon, config } from '@/common'
import { Table, Space, Button, Dropdown, Pagination, Modal, message, Upload } from 'antd';
import { post } from '../fetch';
import './List.scss';

function Main(props: any, ref: any) {
  const location = useLocation()
  const pathname = props.apiUrl || location.pathname
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected_row_keys, setSelectedRowKeys] = useState<any>([]);
  const [selected_row, setSelectedRows] = useState<any>();
  const [request, setRequest] = useState(props.request || {});
  const ope_authority: any = props.opeAuthority || []
  const default_get = useRef(props.defaultGet === undefined ? true : props.defaultGet)
  const member = JSON.parse(localStorage.getItem('member') || '{}')

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [page_msg, setPageMsg] = useState<Array<React.ReactNode>>([]);

  // const [pagination, setPagination] = useState<any>({
  //   showSizeChanger: true,
  //   showQuickJumper: true,
  //   pageSize: 50,
  //   // hideOnSinglePage: true,
  //   showTotal: (total: string) => {
  //     return `共 ${total} 条结果`
  //   },
  //   pageSizeOptions: [20, 50, 100, 200, 500],
  //   onChange: (page: string, page_size: string) => {
  //     setPagination({...pagination,
  //       current: page,
  //       pageSize: page_size
  //     })
  //     setRequest({
  //       ...request,
  //       page,
  //       page_size
  //     })
  //   }
  // });

  // const del = () => {
  //   Modal.confirm({
  //     title: `确认是否删除选中？`,
  //     onOk() {
  //       post(`${pathname}/del`, selected_row_keys).then(data => {
  //         if (data.code) return
  //         getList();
  //         if (props.delSuccces && typeof (props.delSuccces) == "function") props.delSuccces()
  //       })
  //     }
  //   })
  // }

  const getList = async () => {
    setLoading(true)
    setSelectedRowKeys([])
    let req = request
    if (props.requestOriginal) {
      req = { ...props.requestOriginal, ...request }
    }
    return get(pathname, req).then(data => {
      setLoading(false)
      setList(data.data)
      if (data.page) {
        setTotal(data.page.total)
      }
      if (data.sum) {
        let dom: any = ['【总计】']
        data.sum.forEach((e: any, k: number) => {
          dom.push(<span key={k}>{e['name']}：<span className='num'>{e['value']}</span> {e['unit']}；</span>)
        });
        setPageMsg(dom)
      }
      if (props.success) props.success(data.data)
      return data
    })
  }

  // 暴露方法
  useImperativeHandle(ref, () => ({
    getList,
    request: (data: any) => {
      if (data) {
        let request_new = { ...request }
        if (!Object.keys(data).length) request_new = {}
        Object.keys(data).forEach(k => {
          request_new[k] = data[k]
          if (Func.isEmpty(data[k])) delete request_new[k]
        })
        setRequest(request_new)
      }
    },
    reload: () => {
      getList()
    },
    getSelectedRowKeys: () => {
      return selected_row_keys
    },
    getSelectedRows: () => {
      return selected_row
    }
  }));

  useEffect(() => {
    if (default_get.current) {
      getList()
    }
    default_get.current = true
  }, [request])

  let opeLeftMenu: any = []
  if (props.opeAuthority.indexOf("del") > -1) {
    opeLeftMenu.push(
      {
        key: 'del',
        onClick: () => {
          if (!selected_row_keys.length) {
            message.warn('没有任何选中')
            return
          }
          Modal.confirm({
            title: `确认是否删除选中？`,
            onOk() {
              post(`${pathname}/del`, selected_row_keys).then(data => {
                if (data.code) return
                getList();
                if (props.delSuccces && typeof (props.delSuccces) == "function") props.delSuccces()
              })
            }
          })
        },
        label: <span className='red'>批量删除</span>
      }
    )
    if (props.opeAuthority.indexOf("import") > -1) {
      opeLeftMenu = [...opeLeftMenu,
      {
        key: 'template',
        onClick: () => {
          get(pathname + '/downloadTemple', null, 'blob').then(res => {
            res.blob.then((data: any) => {
              message.success('下载成功');
              let filename = decodeURIComponent(res.headers.get('Content-Disposition').match(/filename=(.*);/)[1])
              var url = window.URL.createObjectURL(data);
              var a = document.createElement('a');
              a.href = url;
              a.download = filename
              a.click();
            })
          })
        },
        label: '下载导入模板'
      },
      {
        key: 'import',
        label:
          <Upload
            accept='.xls, .xlsx, .csv'
            headers={{
              'Token-Member': member.token
            }}
            action={config.api.url.default + pathname + '/import'}
            onChange={(info: any) => {
              if (info.file.status === 'done') {
                let rs = info.file.response
                if (rs.code) {
                  message.error(rs.msg)
                  return
                }
                message.success(rs.msg)
                getList()
              }
            }}
            showUploadList={false}
          >
            Excel导入
          </Upload>
      }]
    }
  }

  return (
    <>
      <div className='ope'>
        <Space className='left'>
          <div>已选{selected_row_keys.length}条</div>
          {props.opeLeft}
          {
            opeLeftMenu.length ?
              <Dropdown menu={{
                items: opeLeftMenu
              }}>
                <Button>
                  更多操作
                  <MyIcon type="icon-xiajiantou" />
                </Button>
              </Dropdown> : null
          }
        </Space>
        <Space className='right'>
          {props.opeRight}
        </Space>
      </div>
      <Table
        className="table"
        size="small"
        columns={props.columns}
        dataSource={list}
        rowKey={props.rowKey || 'id'}
        loading={loading}
        rowSelection={(props.rowSelection === false) ? undefined : {
          selectedRowKeys: selected_row_keys, onChange: (k, selectedRows) => {
            setSelectedRowKeys(k)
            setSelectedRows(selectedRows)
          }
        }}
        scroll={{ y: 40 }}
        pagination={false}
        onChange={(pagination, filters, sorter: any) => {
          // 排序
          let request_1 = { ...request }
          if (sorter.order) {
            request_1['order_by'] = [sorter.field, sorter.order]
          } else if (request_1['order_by']) {
            delete request_1['order_by']
          }
          console.log(request_1);

          setRequest(request_1)
        }}
      // pagination={(props.pagination === false) ? undefined : pagination}
      />
      <div className='page'>
        <div className='msg'>{page_msg}</div>
        <div className='right'>
          <div className='total'>共 {total || list.length} 条结果</div>
          {/* <Pagination showQuickJumper hideOnSinglePage showTotal={(total) => {
          return `共 ${total} 条结果`
        }} */}
          <Pagination
            showQuickJumper
            hideOnSinglePage
            defaultPageSize={50}
            current={page}
            total={total}
            pageSizeOptions={[20, 50, 100, 200, 500]}
            onChange={(page: number, page_size: number) => {
              setPage(page)
              setRequest({
                ...request,
                page,
                page_size
              })
            }}
          />
        </div>
      </div>
      {/* <div className={`choice ${selected_row_keys.length ? 'show' : 'hide'}`}>
        <Space>
          {props.selectNode || ''}
          {
            ope_authority.indexOf("del") > -1 && <Button type="primary" danger onClick={del}>删除选中</Button>
          }
          <span>当前选中：{selected_row_keys.length}条</span>
        </Space>
      </div> */}
    </>
  )
}

export default forwardRef(Main)