import { useNavigate, useEffect, config } from '@/common';

function Main(props: any) {
    const navigate = useNavigate()

    useEffect(() => {
        // 已登录跳转
        if (localStorage.getItem('member')) {
            navigate('/')
        } else {
            const par = (new URL(window.location.href)).searchParams
            let token = par.get('_token')
            let app_id = par.get('app_id')
            if (!token) return
            fetch(config.api.url._system + '/loginToken', {
                method: "POST",
                mode: 'cors',
                body: JSON.stringify({
                    token,
                    app_id
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(res => res.json()).then((data: any) => {
                if (data.code) return
                data = data.data
                localStorage.setItem('member', JSON.stringify(data.member))
                localStorage.setItem('module', JSON.stringify(data.module))
                window.location.href = '/'
            })
        }
    }, [])
    return (
        <div>
            跳转中...
        </div>
    )
}

export default Main