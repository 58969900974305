import { get, post, useState, useMemo, useRef, useEffect } from '@/common'
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';

async function fetchOptions(url: string, value: string, label: any) {
    return get(url, { filter: value }).then((data) => {
        if (!data.code) {
            return data.data.map((e: any) => {
                return {
                    label: label ? label(e) : e.name,
                    value: e.id
                }
            })
        }
    })
}

function Main(props: any) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const debounceTimeout = 800
    const [default_status, setDefaultStatus] = useState(true)
    const [value, setValue] = useState<any>();

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value: any) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            // setOptions([]);
            setFetching(true);
            fetchOptions(props.url, value, props.label).then((newOptions: any) => {
                if (newOptions !== undefined) {
                    if (fetchId !== fetchRef.current) {
                        return;
                    }
                    setOptions(newOptions);
                    setFetching(false);
                }
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    const onChange = (e: any) => {
        setDefaultStatus(false)
        props.onChange(e)
    }

    useEffect(() => {
        setValue(props.value)
        if (default_status && props.value) {
            post(props.url, props.mode === 'multiple' ? props.value : [props.value], false).then((data) => {
                let arr: any = []
                data.data.map((e: any) => {
                    arr.push({
                        label: props.label ? props.label(e) : e.name,
                        value: e.id
                    })
                })
                setOptions(arr)
            })
        }
    }, [props.value])

    return (
        <Select
            showSearch
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : undefined}
            options={options}
            {...props}
            value={value}
            onChange={onChange}
        />
    )
}

export default Main