import { useState, useImperativeHandle, get, useLocation, forwardRef } from '@/common'
import { Modal, Skeleton } from 'antd';
import './Detail.scss';

function Main(props: any, ref: any) {
    const location = useLocation()
    const pathname = props.apiUrl || location.pathname
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    // 暴露方法
    useImperativeHandle(ref, () => ({
        open: (id: number) => {
            setOpen(true)
            setLoading(true)
            get(`${pathname}/detail/${id}`).then(data => {
                setLoading(false)
                if (props.success) props.success(data.data)
            })
        },
    }));
    return (
        <Modal open={open} title={props.title || `${props.name}详情`} footer={null} onCancel={() => { setOpen(false) }}>
            <Skeleton active loading={loading}>
                <div className='detail'>
                    {props.children}
                </div>
            </Skeleton>
        </Modal>
    )
}
export default forwardRef(Main)