import { useImperativeHandle, forwardRef, get, useLocation, useEffect, useState } from '@/common'
import { Button, Form, Space, message, Modal, Row, Col, Checkbox } from 'antd';
import './Search.scss';

function Main(props: any, ref: any) {
    const location = useLocation()
    const pathname = props.apiUrl || location.pathname
    const [form] = Form.useForm();
    const search_text = props.searchText || '查询'
    const pattern = props.pattern || 'submit'
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState<any>();
    const [export_field_all, setFieldDictAll] = useState(false)
    const [export_fields, setExportFields] = useState([])

    const onFinish = (data: any) => {
        let request = { ...data }
        if (props.searchBefore && typeof (props.searchBefore) == "function") {
            let rt = props.searchBefore(data)
            if (rt) request = rt
        }
        // 格式化数据
        if (props.format) {
            const date = 'YYYY-MM-DD'
            const date_time = 'YYYY-MM-DD HH:mm:ss'
            const time = 'HH:mm:ss'
            if (props.format.date_range) {
                props.format.date_range.forEach((e: any) => {
                    if (!data[e]) return
                    request[e] = []
                    data[e].forEach((e_1: any, k_1: any) => {
                        request[e][k_1] = e_1.format(date)
                    })
                })
            }
            if (props.format.date_time_range) {
                props.format.date_time_range.forEach((e: any) => {
                    if (!data[e]) return
                    request[e] = []
                    data[e].forEach((e_1: any, k_1: any) => {
                        request[e][k_1] = e_1.format(date_time)
                    })
                })
            }
            if (props.format.date) {
                props.format.date.forEach((e: any) => {
                    if (!data[e]) return
                    request[e] = data[e].format(date)
                })
            }
            if (props.format.date_time) {
                props.format.date_time.forEach((e: any) => {
                    if (!data[e]) return
                    request[e] = data[e].format(date_time)
                })
            }
        }
        console.log(data);

        props.nref.list.current.request(request)
    }
    // 暴露方法
    useImperativeHandle(ref, () => ({
        setFieldsValue: (data: any) => {
            form.setFieldsValue(data)
        },
        search: () => {
            onFinish(form.getFieldsValue())
        },
        getData: () => {
            return form.getFieldsValue()
        },
    }
    ));

    const exportRs = () => {
        if (loading) return
        let data = form.getFieldsValue()
        data.action = 'export'
        // if (props.searchBefore && typeof (props.searchBefore) == "function") props.searchBefore(data)

        // let request = {}
        // if (props.searchBefore && typeof (props.searchBefore) == "function") {
        //     let rt = props.searchBefore(data)
        //     request = rt ? rt : data
        // } else {
        //     request = data
        // }
        if (props.exportField) {
            data.export_fields = export_fields
        }
        if (props.searchBefore && typeof (props.searchBefore) == "function") {
            props.searchBefore(data)
        }

        setLoading(true)
        let hide = message.loading('数据导出中...', 0)
        get(pathname, data, 'blob').then(res => {
            res.blob.then((data: any) => {
                hide()
                message.success('导出成功');
                setLoading(false)
                let filename = decodeURIComponent(res.headers.get('Content-Disposition').match(/filename=(.*);/)[1])
                var url = window.URL.createObjectURL(data);
                var a = document.createElement('a');
                a.href = url;
                a.download = filename
                a.click();
            })
        })
    }

    useEffect(() => {
        if (props.setDefault && typeof (props.setDefault) == "function") props.setDefault(form)
        if (props.request) {
            form.setFieldsValue(props.request)
        }
    }, [])

    return (
        <>
            <Form form={form} className='search' onFinish={onFinish} hidden={props.hidden} onValuesChange={(changedValues, allValues) => {
                if (pattern === 'change') {
                    onFinish(allValues)
                }
            }}>
                {props.children}
                {
                    pattern === 'submit' &&
                    <Space>
                        <Button type="primary" htmlType="submit">{search_text}</Button>
                        <Button onClick={() => { form.resetFields(); props.nref.list.current.request({}) }}>重置</Button>
                        {
                            props.opeAuthority.indexOf("export") > -1 && <a onClick={() => {
                                if (props.exportField) {
                                    setOpen(true)
                                    setFieldDictAll(true)
                                    let export_field: any = []
                                    Object.keys(props.exportField).map((e: any) => {
                                        export_field.push(e)
                                    })
                                    setExportFields(export_field)
                                } else {
                                    exportRs()
                                }
                            }}>导出查询{props.name}</a>
                        }
                    </Space>
                }
            </Form>
            {
                props.exportField &&
                <Modal title={`导出查询${props.name}`} open={open} okText='导出' onCancel={() => { setOpen(false) }} onOk={() => {
                    if (!export_fields.length) {
                        message.error('至少选择一项')
                        return
                    }
                    exportRs()
                }}>
                    <Space style={{ marginBottom: '10px' }}>
                        <Checkbox checked={export_field_all} onChange={(e: any) => {
                            setFieldDictAll(e.target.checked)
                            if (e.target.checked) {
                                let export_field: any = []
                                Object.keys(props.exportField).map((e: any) => {
                                    export_field.push(e)
                                })
                                setExportFields(export_field)
                            } else {
                                setExportFields([])
                            }
                        }}>全选</Checkbox>
                        <div>报表字段（共{Object.keys(props.exportField).length}个）</div>
                    </Space>
                    <Checkbox.Group value={export_fields} onChange={(e: any) => {
                        if (e.length === Object.keys(props.exportField).length) {
                            setFieldDictAll(true)
                        } else {
                            setFieldDictAll(false)
                        }
                        setExportFields(e)
                    }}>
                        <Row gutter={[24, 10]}>
                            {
                                Object.keys(props.exportField).map((e: any) => {
                                    return <Col span={6}>
                                        <Checkbox value={e} key={e}>{props.exportField[e]}</Checkbox>
                                    </Col>
                                })
                            }
                        </Row>
                    </Checkbox.Group>
                </Modal>
            }
        </>
    )
}
export default forwardRef(Main)