import React, { post, useState, MyIcon, useEffect, useNavigate, useLocation, get, Func, config } from '@/common';
import { Outlet } from 'react-router-dom';
import { Layout, Menu, Avatar, Dropdown, Modal, Badge, Tooltip, Space } from 'antd'; import {
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons';
import '@/assets/style/panel.scss';

const { Header, Sider, Content } = Layout;

function Main() {
  const navigate = useNavigate()
  const pathname = useLocation().pathname
  const platforms = JSON.parse(localStorage.getItem('platforms') || '{}')
  const modules = JSON.parse(localStorage.getItem('module') || '{}')
  const member = JSON.parse(localStorage.getItem('member') || '{}')
  const [index_data, setIndexData] = useState<any>({});
  const menu = modules[config.app.id]
  const [menu_items, setMenuItems] = useState<any>();
  const [menu_open_keys, setMenuOpenKeys] = useState<any>();
  const [menu_selected_keys, setMenuSelectedKeys] = useState<any>();
  const [user_drop, setUserDrop] = useState<any>();
  const [platforms_menu, setPlatformsMenu] = useState<any>();
  const [collapsed, setCollapsed] = useState(false);

  const onOpenChange = (e: any) => {
    setMenuOpenKeys(e)
  }

  const menuClick = (e: any) => {
    let key = e.key.split('-')
    navigate(menu[key[0]].children[key[1]].url)
  }


  const logout = () => {
    Modal.confirm({
      title: "确认是否退出登陆？",
      onOk() {
        post('/_system/logout')
        localStorage.removeItem('member')
        localStorage.removeItem('module')
        let url = '/login'
        // if (platform_now !== 'system') url = `/${platform_now}/login`
        navigate(url)
      }
    })
  }

  useEffect(() => {
    // 初始化菜单
    let menu_items_default: any = []
    let menu_open_keys_default: any = []
    menu.forEach((e: any, k: number) => {
      let item: any = {
        label: e.name,
        key: k,
        icon: <MyIcon type={`icon-${e.ico}`} />,
        children: []
      }
      e.children.forEach((e_1: any, k_1: any) => {
        let item_children = {
          label: e_1.name,
          key: `${k}-${k_1}`,
        }
        item.children.push(item_children)
        if (e_1.url === pathname) {
          setMenuSelectedKeys([item_children.key])
        }
      })
      menu_items_default.push(item)
      menu_open_keys_default.push(k.toString())
    })
    setMenuItems(menu_items_default)
    setMenuOpenKeys(menu_open_keys_default)
    setUserDrop([
      {
        onClick: () => {
          window.location.href = `${config.url.system}/my/my`
        },
        icon: <MyIcon type="icon-kehuxinxi-" />,
        label: (<div>个人中心</div>),
      },
      {
        onClick: () => { logout() },
        icon: <MyIcon type="icon-tuichu" />,
        label: (<div>退出登陆</div>),
      }
    ])
    let platforms_menu_default: any = []
    Object.keys(platforms).forEach((key) => {
      if (key === config.app.id || !modules[key]) return
      platforms_menu_default.push(
        {
          key,
          label: (
            <a target={platforms[key].target} href={platforms[key].url}>
              {platforms[key].name}
            </a>
          ),
        })
    })
    setPlatformsMenu(platforms_menu_default)
  }, [])

  useEffect(() => {
    getIndex()
    // 路径改变菜单更新
    if (menu_items) {
      menu.forEach((e: any, k: number) => {
        e.children.forEach((e_1: any, k_1: any) => {
          if (e_1.url === pathname) {
            setMenuSelectedKeys([`${k}-${k_1}`])
            if (menu_open_keys.indexOf(k) === -1) {
              setMenuOpenKeys([...menu_open_keys, k.toString()])
            }
          }
        })
      })
    }
  }, [pathname])

  const getIndex = () => {
    get('/_system/index').then(data => {
      setIndexData(data.data)
    })
  }

  return (
    <Layout className="mos">
      <div>
        <div>
          <div className='left'>
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              onClick: () => { setCollapsed(!collapsed) },
            })}
            {/* <a className="logo" onClick={() => navigate(platforms[config.app.id].url)}>
              {platforms[config.app.id].name}
            </a> */}
            <Dropdown menu={{ items: platforms_menu }} className="logo">
              <Space onClick={() => navigate(platforms[config.app.id].url)}>
                {platforms[config.app.id].name}
                <DownOutlined />
              </Space>
            </Dropdown>
          </div>
          <div className='right'>
            <div className='btns'>
              <Tooltip title="消息通知">
                <Badge count={index_data.unread} size="small">
                  <MyIcon type="icon-xiaoxizhongxin" onClick={() => {
                    window.location.href = `${config.url.system}/message/manage`
                  }} />
                </Badge>
              </Tooltip>
            </div>
            <Dropdown menu={{ items: user_drop }}>
              <div className="user">
                <Avatar size="small" src={member.avatar} />
                <span className="name">{member.name}</span>
                <MyIcon type="icon-xiajiantou" />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className={collapsed ? 'collapsed' : ''}>
        <div>
          <Menu inlineCollapsed={collapsed} items={menu_items} mode="inline" onClick={menuClick} openKeys={menu_open_keys} selectedKeys={menu_selected_keys} onOpenChange={onOpenChange} />
        </div>
        <div>
          <Outlet />
        </div>
      </div>
      {/* <Header >
        <div>
          <div className="logo">
            <a onClick={() => navigate(platforms[config.app.id].url)}>{platforms[config.app.id].name}</a>
          </div>
        </div>
      </Header>
      <Sider >
      </Sider>
      <Layout>
        <Layout>
          <Content >
            <Outlet />
          </Content>
        </Layout>
      </Layout> */}
    </Layout>
  )
}

export default Main